<template>
    <aside>
        <div class="border border-grey-2 rounded-3xl grid gap-4 p-8">
            <div class="flex" :class="{ 'text-secondary': isActive('acc') }">
                <font-awesome-icon icon="fa-solid fa-gear" size="lg" />
                <div class="ml-4 font-bold">
                    <router-link to="/acc">Account Setting</router-link>
                </div>
            </div>
            <div class="flex" :class="{ 'text-secondary': isActive('myvoucher') }">
                <font-awesome-icon icon="fa-solid fa-percent" size="lg" />
                <div class="ml-5 font-bold">
                    <router-link to="/myvoucher">Voucher</router-link>
                </div>
            </div>
            <div class="flex" :class="{ 'text-secondary': isActive('waitingReviews') }">
                <font-awesome-icon icon="fa-solid fa-message" size="lg" />
                <div class="ml-4 font-bold">
                    <router-link to="/waitingReviews">Reviews</router-link>
                </div>
            </div>
            <div class="flex" :class="{ 'text-secondary': isActive('crossselling') }">
                <font-awesome-icon icon="fa-solid fa-message" size="lg" />
                <div class="ml-4 font-bold">
                    <router-link to="/crossselling">Cross Selling</router-link>
                </div>
            </div>
            <div class="flex" :class="{ 'text-secondary': isActive('article') }">
                <font-awesome-icon icon="fa-light fa-file-lines" size="lg" />
                <div class="ml-4 font-bold">
                    <router-link to="/article">Article</router-link>
                </div>
            </div>
        </div>
    </aside>
</template>

<script>
    export default {
        props: {
            currentPath: String
        },

        methods: {
            isActive(path) {
                return this.currentPath == path;
            }
        }
    };
</script>
