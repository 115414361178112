<template>
  <!-- Container -->
  <div class="container mx-auto border border-grey-2 rounded-lg my-2 flex">
    <div class="flex w-full">
      <SideBarAdmin :menu="admin" />
      <div
        class="flex h-screen w-11/12 -ml-28 my-2 border border-black rounded pl-4 pr-4 col-lg mt-7 mr-7 pt-2 pb-2"
      ></div>
    </div>
  </div>
</template>
<script>
import SideBarAdmin from "../components/SideBarAdmin.vue";
/* add fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import specific icons */
import {
  faUser,
  faGear,
  faPercent,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUser, faGear, faPercent, faMessage);

export default {
  name: "Admin",
  components: {
    SideBarAdmin,
  },
  data() {
    return {
      admin: "admin",
    };
  },
  mounted() {},
};
</script>
<style scoped></style>
